import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../../components/layout";
import ProjectHeader from "../../../components/layouts/ProjectHeader";
import { TechnologySection, TechEntry } from "../../../sections/Project-Page/TechnologySection";
import { BreakdownSection, BreakdownGrid } from "../../../sections/Project-Page/BreakdownSection";

const CarDaddy = () => {

    const data = useStaticQuery(
        graphql`
            query {
                mobileImage: file(relativePath: { eq: "cardaddy1.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 490, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                desktopImage: file(relativePath: { eq: "cardaddy1.jpg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                gallery1: file(relativePath: { eq: "cardaddy1.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                      }
                    }
                }
            }
        `
    )

    const sourcesHeader = [
        data.mobileImage.childImageSharp.fluid,
        {
          ...data.desktopImage.childImageSharp.fluid,
          media: `(min-width: 491px)`,
        },
    ]

    const galleryImages = [
        data.gallery1.childImageSharp.fluid.src
    ];

    const headerContent = {
        github_link: null,
        website_link: null,
        sources: sourcesHeader,
        headline: "CarDaddy - In Development",
        copy: "Find out how to maintain your car throughout its life span. Get the checkup information and solution for your specific make and model.",
        galleryImages: galleryImages
    }

    const TechUsed = [
        "Swift",
        "Spring",
        "NodeJS",
        "Docker",
        "MySQL",
        "Kubernetes"
    ]

    const TechEntries = TechUsed.map((techName) => (
        <TechEntry key={techName} TechItem={techName} />
    ))
    
    return (
        <Layout>
            <ProjectHeader content={headerContent} />
            <TechnologySection>
                {TechEntries}
            </TechnologySection>

            <BreakdownGrid>

                <BreakdownSection title="Details">
                    <p>
                        CarDaddy is a vehicle maintenance app for iOS that helps inform you about the current and upcoming
                        needs for your vehicle. It's the project my team and I decided to make for our year-long 2020 graduate 
                        capstone at Sheridan College. The role I took responsibility for on the team was the backend architecture
                        and development.
                    </p>

                    <p>
                        For our backend, we use microservices to process client requests. We try to aggregate the data provided in our
                        app from a variety of third party API's and manipulate it for our use cases. The core of our backend is built in
                        Java using the Spring framework to aid in rapid development. Other key services and our gateway is written in NodeJS,
                        which offers non-blocking processing with a huge pool of open source code to get this up and running quickly.
                    </p>
                </BreakdownSection>

                <BreakdownSection title="Using A Microservices Architecture">
                    <p>
                        There are <i>two reasons</i> why using this architecture made sense. First was <b>work distribution</b> being
                        an issue with multiple team members. Instead of having 2 or 3 people building all in the same codebase and having
                        to communicate, we can divide work and just give people requirements only. With everyone dealing with different 
                        work hours and time constraints it just made sense to let people work on their own to put together the whole product.
                    </p>

                    <p>
                        The second issue was the <b>scalability</b> of the whole application. The App Store takes care of frontend distribution
                        but the backend needs to ability to potentially service thousands of active users across North America. With the volume
                        of requests, we need to distribute the logic into services that manage certain portions of the application. Once the logic
                        is decoupled we can load balance the requests across Kubernetes pods.

                    </p>
                </BreakdownSection>

            </BreakdownGrid>

        </Layout>
    );

}

export default CarDaddy;